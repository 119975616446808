import React, { useEffect, useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Helmet } from 'react-helmet'
import HeaderSEO from '../components/partials/HeaderSEO'

import Layout from '../components/Layout'

import aiven_hero from '../resources/images/aiven_page/kafka_hero.svg'
import undraw_chatting_2yvo from '../resources/images/vectors/undraw_chatting_2yvo.svg'

import Form from '../components/forms/Form'

import { getEmailLocalStorage } from '../helpers/getEmailStorage'
import { getCookieByName } from '../helpers/getCookieByName'

declare const window: any
interface StateProps {
  contactUsState: ContactState
}
interface ContactState {
  pardotHandler: string
  headingTitle: string
  displayParagraph: string
  image: string
  messagePlaceholder: string
  contactSource: string
  validateEmailFlag: boolean
}
// const gettingStartedOnAwsMsk: React.FC<StateProps> = () => {
const contactUs: React.FC<StateProps> = () => {
  const data = useStaticQuery(graphql`
    query ContactFormSEO {
      allContentfulPages(
        filter: { pageReference: { pageIdentifier: { eq: "Form_ContactUs" } } }
      ) {
        edges {
          node {
            title {
              title
            }
            keywords {
              keywords
            }
            description {
              description
            }
            oGimage {
              fluid(quality: 100) {
                src
              }
            }
          }
        }
      }
    }
  `)

  const pageSEO = data.allContentfulPages.edges[0].node

  const [contactUsState, setContactUsState] = useState<ContactState>({
    pardotHandler: 'https://go.pardot.com/l/877242/2020-08-04/4fkwl9',
    headingTitle: 'Contact Us',
    displayParagraph: "We're here to help",
    image: undraw_chatting_2yvo,
    messagePlaceholder: 'Tell us about your use case',
    contactSource: 'NONE',
    validateEmailFlag: false,
  })

  const {
    pardotHandler,
    headingTitle,
    displayParagraph,
    image,
    messagePlaceholder,
    contactSource,
    validateEmailFlag,
  } = contactUsState

  useEffect(() => {
    var url_string = window.location.href
    var url = new URL(url_string)
    var source = url.searchParams.get('s')
    if (source) {
      if (source === 'aiven-discount') {
        setContactUsState({
          ...contactUsState,
          contactSource: source,
          headingTitle: 'Contact us to trial Aiven + Lenses',
          image: aiven_hero,
          displayParagraph:
            'Plus claim your $350 free Kafka credit - months worth of free fully managed Kafka.',
        })
      } else if (source === 'quote-developer-team') {
        setContactUsState({
          ...contactUsState,
          headingTitle: 'Just some quick details...',
          displayParagraph:
            'Someone will be in touch to complete your purchase.',
          contactSource: source,
          pardotHandler: 'https://go.pardot.com/l/877242/2020-08-04/4fkwlc',
          validateEmailFlag: true,
        })
      } else if (source === 'quote' || source === 'quote-platform-team') {
        setContactUsState({
          ...contactUsState,
          headingTitle: 'Get a quote',
          displayParagraph:
            'Someone will be in touch to complete your purchase.',
          messagePlaceholder:
            'Tell us about your Kafka environments & use cases',
          contactSource: source,
          pardotHandler: 'https://go.pardot.com/l/877242/2020-08-04/4fkwlc',
          validateEmailFlag: true,
        })
      }
      let emailStorage = getEmailLocalStorage()
      if (emailStorage) {
        document.querySelector<HTMLInputElement>('#email').value = emailStorage
      }
    }
  }, [])

  const handleFormSubmit = () => {
    document.querySelector<HTMLInputElement>('#splunk_id').value =
      getCookieByName('mintjs%3Auuid')
    document.querySelector<HTMLInputElement>('#timestamp').value =
      new Date().toLocaleString()
    document.querySelector<HTMLInputElement>(
      '#zoominfo-default-form'
    ).disabled = true
  }
  return (
    <Layout onlyLogo={true}>
      <HeaderSEO pageSEO={pageSEO} />
      <Helmet>
        <link rel="canonical" href="https://lenses.io/contact-us/" />
      </Helmet>
      <section>
        <div className="container-1 hero-margin mb-4 mobile-padding-reset">
          <div className="d-flex flex-wrap justify-content-center">
            <div className="col-lg-4 col-10 col-md-8 pt-5 px-2">
              <h1>{headingTitle}</h1>
              <h2 className="hero-description-dark pb-0">{displayParagraph}</h2>

              <img
                className="img-fluid mx-auto d-block pt-5 mobile-padding-reset"
                src={image}
                alt="Contact Lenses team"
              />

              <p className="hero-description-dark pt-5 mobile-padding-reset">
                <span style={{ fontSize: '18px' }}>
                  Contact Sales at{' '}
                  <a
                    href="mailto:sales@lenses.io"
                    id="contactus-link-salesemail"
                  >
                    sales@lenses.io
                  </a>
                </span>{' '}
                <br />
                <span style={{ fontSize: '18px' }}>
                  Contact Support at{' '}
                  <a
                    href="mailto:support@lenses.io"
                    id="contactus-link-support"
                  >
                    support@lenses.io
                  </a>
                </span>
                <br />
                <span style={{ fontSize: '18px' }}>
                  Other Enquiries at{' '}
                  <a href="mailto:info@lenses.io" id="contactus-link-infoemail">
                    info@lenses.io
                  </a>
                </span>
              </p>
            </div>

            <div className="px-0 col-lg-4 offset-lg-2 offset-0 col-sm-7 col-10 pt-5 mobile-margin-bottom">
              <div className="footer-color py-5 px-2 rounded">
                <div className="d-flex flex-wrap">
                  <div className="col-md-12 mobile-padding-reset">
                    {/* ATTENTION! Form ID needs to be matching Zoominfo settings as described here https://landoop.atlassian.net/wiki/spaces/MAR/pages/2910453761/Zoominfo+Website+integration */}
                    <Form
                      formId="zoominfo-default-form"
                      onSubmit={handleFormSubmit}
                      action={pardotHandler}
                      inputId="formsubmit"
                      inputValue="Send Now"
                      firstName={'required'}
                      lastName={'required'}
                      email={'required'}
                      company={'notRequired'}
                      message={'required'}
                      messageValue={messagePlaceholder}
                      type="contactUs"
                      contactSource={contactSource}
                      validateEmailFlag={validateEmailFlag}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default contactUs
